var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"pt-5",attrs:{"column":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{staticClass:"pt0 pb0"},[_c('v-flex',{attrs:{"xs4":""}},[_c('h2',[_vm._v("Applications")])]),_c('v-flex',{attrs:{"xs4":""}},[_c('v-text-field',{staticClass:"pa-0",attrs:{"label":"Find client (email, name, phone)","append-icon":"mdi-magnify","loading":_vm.isLoading},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1)],1)],1),_c('v-flex',{staticClass:"pt-5",attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.clients,"options":_vm.options,"server-items-length":_vm.countItems,"loading":_vm.isLoading,"disable-filtering":"","footer-props":{
        itemsPerPageOptions: [15],
      },"items-per-page":15,"page":_vm.page},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.trial_up",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"input-value":item.is_trial_show_up === '1'},on:{"change":function () { return _vm.changeStatus(
            item.id, item.is_trial_show_up === '0' ? '1' : '0', 'is_trial_show_up', item.clickid
            ); }}})]}},{key:"item.membership_paid",fn:function(ref){
            var item = ref.item;
return [_c('v-checkbox',{attrs:{"input-value":item.is_membership_paid === '1'},on:{"change":function () { return _vm.changeStatus(
            item.id,
            item.is_membership_paid === '0' ? '1' : '0',
            'is_membership_paid',
            item.clickid
            ); }}})]}},{key:"item.sales_value",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{on:{"click":function () { return _vm.openDialog(item); }}},[_vm._v(_vm._s(item.sales_value === undefined ? 0 : item.sales_value))])]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Sales Value")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Value","type":"number"},model:{value:(_vm.currentDialogItem.sales_value),callback:function ($$v) {_vm.$set(_vm.currentDialogItem, "sales_value", $$v)},expression:"currentDialogItem.sales_value"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.isLoading,"disabled":_vm.isLoading},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.isLoading,"disabled":_vm.isLoading},on:{"click":_vm.changeSalesValue}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }