<template>
  <v-app>
    <v-main class="login-page">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md8>
            <v-card rounded="xl" class="overflow-hidden" flat>
              <v-card-text class="pa-0">
                <v-layout align-center justify-center>
                  <v-flex xs12 sm8 md6 class="image-container pa-15" align-center justify-center>
                    <v-img
                      max-width="150"
                      class="image-container__image pa-10"
                      src="@/assets/login-support.png"
                      contain
                    >
                    </v-img>
                    <v-img
                      max-width="200"
                      class="image-container__image pa-10"
                      src="@/assets/fitintime_logo.svg"
                      contain
                    >
                    </v-img>
                  </v-flex>
                  <v-flex xs12 sm8 md6 class="pa-5 form-container" align-center justify-center>
                    <h2 class="pb-5">Login</h2>
                    <v-form class="form-container__form">
                      <v-text-field
                        prepend-icon="mdi-email"
                        name="email"
                        label="Email"
                        type="text"
                        v-model="email"
                      >
                      </v-text-field>
                      <v-text-field
                        prepend-icon="mdi-lock"
                        name="password"
                        label="Password"
                        id="password"
                        type="password"
                        v-model="password"
                      >
                      </v-text-field>
                      <div class="button-container mt-2">
                        <v-btn
                          color="primary"
                          outlined
                          text
                          :loading="isLoading"
                          @click="login"
                        >
                          Login
                        </v-btn>
                      </div>
                    </v-form>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <!--            <v-card-actions>-->
              <!--              <v-spacer></v-spacer>-->
              <!--              <v-btn color="cyan" class="white&#45;&#45;text">Login</v-btn>-->
              <!--            </v-card-actions>-->
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      isLoading: false,
      email: '',
      password: '',
    };
  },
  methods: {
    login() {
      console.log('click');
      this.isLoading = true;
      this.$auth.login({
        data: {
          email: this.email,
          password: this.password,
        },
        rememberMe: true,
        redirect: {
          name: 'Bookings',
        },
        fetchUser: true,
      })
        .then((response) => {
          this.isLoading = false;
          console.log(`success ${this.context}`);
          console.log(response.headers);
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          // this.error = error.response.data.message;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.image-container {
  display: flex;
  flex-direction: column;
  background: #003f88;
}

.login-page {
  background: #1a8fff;
}

.form-container {
  display: flex;
  flex-direction: column;

  &__form {
    width: 100%;
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
